import * as Sentry from '@sentry/vue';
import { getCurrentPlatform } from '@/utils-v2/helpers/getCurrentPlatform.ts';

const appEnv = import.meta.env.VITE_APP_DEPLOYMENT;
const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
const errorCache = new Map();

const ignoreErrors = [
  'UnhandledRejection', // 3rd party plugins
  'NavigationDuplicated', // router
  'Navigation cancelled', // router
  "Can't find variable: Notification", // safari notification
  'Request Aborted', // самостоятельно закрытые реквесты
  'Notification is not defined', // safari notification
  "null is not an object (evaluating 'e.offsetWidth')", // ymaps
  "Cannot read properties of null (reading 'offsetWidth')", // ymaps
  '/business/company', // get company 404
  'Network Error',
  'Importing a module script failed',
  'Unable to preload CSS',
  'Load failed',
  'Request failed with status code 404',
];

const analyticsBlacklist = ['yandex', 'google', 'amplitude', 'ymetrica1', 'yandexmetrica'];

// Sentry
export const initSentry = app => {
  if (['PRODUCTION', 'DEVELOPMENT'].includes(appEnv)) {
    Sentry.init({
      app,
      dsn: sentryDsn,
      tracesSampleRate: 1.0,
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
      environment: appEnv,
      initialScope: scope => {
        const runtime = getCurrentPlatform();

        scope.setTags({
          cause: 'js',
          runtime,
        });

        return scope;
      },
      beforeBreadcrumb(crumb) {
        const isCategoryNav = crumb?.data?.category === 'navigation';
        const url = crumb?.data?.url || '';
        const isAnalyticsRequest = Boolean(analyticsBlacklist.find(item => url.includes(item)));

        if (isCategoryNav || isAnalyticsRequest) {
          return null;
        }

        return crumb;
      },
      beforeSend: (event, hint) => {
        const error = hint.originalException;

        if (error && typeof error === 'object' && 'message' in error) {
          const message = error.message;

          if (typeof message === 'string' && ignoreErrors.some(sentence => message.indexOf(sentence) !== -1)) {
            return null;
          }
        }

        return event;
      },
    });
  }
};

export const sentryDebug = ({ title, message, args }) => {
  Sentry.withScope(scope => {
    scope.addBreadcrumb({
      category: title,
      level: Sentry.Severity.Debug,
      message,
    });

    let extras = {
      pageUrl: window.location.href,
    };

    if (args) {
      extras.args = JSON.stringify(args);
    }

    scope.setExtras(extras);

    const error = new Error();
    error.name = title;
    error.message = message;

    Sentry.setTag('cause', 'custom');
    Sentry.captureMessage(error);
  });
};

export const setSentryUserId = id => {
  Sentry.configureScope(scope => {
    scope.setUser({ id });
  });
};

async function sendEventWithRetry(event, retryCount = 0, initialDelay = 1000) {
  try {
    await Sentry.captureEvent(event);

  } catch (error) {
    if (error.response && error.response.status === 429) {
      const retryAfter = parseInt(error.response.headers.get('retry-after'), 10) || 1;
      const delay = Math.min(Math.pow(2, retryCount) * initialDelay + (Math.random() * 0.4 - 0.2) * initialDelay, 60000);

      console.warn(`Sentry rate limit hit. Retrying in ${delay / 1000} seconds... (Attempt ${retryCount + 1})`);
      await new Promise(resolve => setTimeout(resolve, delay));

      if (retryCount < 5) {
        await sendEventWithRetry(event, retryCount + 1);
      } else {
        console.error("Max retry attempts reached for Sentry event.");
      }
    } else {
      throw error;
    }
  }
}

export const pushApiError = response => {
  Sentry.withScope(scope => {
    const { url, baseURL } = response.config;
    const status = response.status || response.response?.status;
    const name = `${status}  ${url}`;
    const allUrl = new URL(baseURL + url);
    const queryParams = Object.fromEntries(allUrl.searchParams.entries());

    const cacheKey = `${status}-${url}`;
    const currentTime = Date.now();
    const cacheExpiration = 60 * 1000; // 1 minute

    if (errorCache.has(cacheKey) && currentTime - errorCache.get(cacheKey) < cacheExpiration) {
      console.warn(`Skipping duplicate error for ${cacheKey}`);
      return;
    }

    errorCache.set(cacheKey, currentTime);
    errorCache.forEach((timestamp, key) => {
      if (currentTime - timestamp > cacheExpiration) {
        errorCache.delete(key); // Cleanup expired cache
      }
    });

    scope.setLevel('error');
    scope.setExtras({
      status: status,
      url,
      params: response.config.params || queryParams,
      payload: JSON.stringify(response.config.data || null),
      response: JSON.stringify(response?.response?.data || null),
    });

    const error = new Error(name);
    Sentry.setTag('cause', 'api');

    sendEventWithRetry(error)
      .catch(err => {
        console.error("Error sending event to Sentry:", err);
      });
  });
};
